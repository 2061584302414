const SBS = {
    Splides: {} // An empty object to store all Splide instances
};

// Attach SBS to the global `window` object
window.SBS = SBS;


// Optional: If you need compatibility with module systems
if (typeof module !== 'undefined' && module.exports) {
    module.exports = SBS;
}